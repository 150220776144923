import type { Instructions } from "~/types/onboarding/onboardingScreen";

/* eslint quote-props: ["error", "always"] */
export const onboardingTest: Instructions = {
  "start": {
    "conditions": [],
    "fallback": "screenid:onboarding.encouraging_flow.goal.main_goal"
  },
  "screens": [
    {
      "type": "overall_goal",
      "id": "screenid:onboarding.encouraging_flow.goal.main_goal",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.goal.main_goal.question"
      },
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.goal.reason"
      },
      "showElseOption": true
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.goal.reason",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.goal.reason.question"
      },
      "options": [
        {
          "emoji": "😎",
          "translationKey": "onboarding.encouraging_flow.goal.reason.confidence",
          "trackingName": "onboarding.encouraging_flow.goal.reason.confidence",
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.goal.additional_goal"
          },
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "💚",
          "translationKey": "onboarding.encouraging_flow.goal.reason.health",
          "trackingName": "onboarding.encouraging_flow.goal.reason.health",
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.goal.additional_goal"
          },
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ]
    },
    {
      "type": "multi_choice",
      "id": "screenid:onboarding.encouraging_flow.goal.additional_goal",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.goal.additional_goal.question"
      },
      "options": [
        {
          "emoji": "🍲",
          "translationKey": "onboarding.encouraging_flow.goal.additional_goal.food",
          "trackingName": "onboarding.encouraging_flow.goal.additional_goal.food",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🧑‍🍳",
          "translationKey": "onboarding.encouraging_flow.goal.additional_goal.cooking",
          "trackingName": "onboarding.encouraging_flow.goal.additional_goal.cooking",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.past.challenges_affirmation"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.past.challenges_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.past.challenges_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.past.challenges_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/welcome_greetings.light"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.gender"
      }
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.birthday"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.gender",
      "staticScreenType": "sex"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.height"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.birthday",
      "staticScreenType": "birthday"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.activity_level"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.height",
      "staticScreenType": "height"
    },
    {
      "type": "activity_level",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "registration.activity.headline"
      },
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.current_weight"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.activity_level"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal_goal.goal_weight"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.current_weight",
      "staticScreenType": "current_weight"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.diet.dietary_preferences"
      },
      "id": "screenid:onboarding.encouraging_flow.personal_goal.goal_weight",
      "staticScreenType": "target_weight"
    },
    {
      "type": "diet",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "registration.dietary_preferences.headline"
      },
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.diet.recipes_affirmation"
      },
      "id": "screenid:onboarding.encouraging_flow.diet.dietary_preferences"
    },
    {
      "type": "illustrations_recipes",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": ""
      },
      "id": "screenid:onboarding.encouraging_flow.diet.recipes_affirmation",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal_goal.event_date"
      }
    },
    {
      "type": "date",
      "id": "screenid:onboarding.encouraging_flow.personal_goal.event_date",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.personal_goal.event_date.title"
      },
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.personalized_program_affirmation"
      }
    },
    {
      "type": "info_list",
      "id": "screenid:onboarding.encouraging_flow.plan.personalized_program_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.plan.personalized_program_affirmation.title"
      },
      "imageSize": "MEDIUM",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.plan.personalized_program_affirmation.light"
      },
      "infoList": [
        {
          "translationKey": "onboarding.encouraging_flow.plan.personalized_program_affirmation.results",
          "emoji": "⛳",
          "titleTranslationKey": "",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "translationKey": "onboarding.encouraging_flow.plan.personalized_program_affirmation.habits",
          "emoji": "🚀",
          "titleTranslationKey": "",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
      }
    },
    {
      "type": "prepare_plan",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": ""
      },
      "steps": [],
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.benefits"
      },
      "id": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
    },
    {
      "type": "static",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.benefit_screen.heading.what_you_can_look_forward_to"
      },
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:end"
      },
      "staticScreenType": "benefits",
      "id": "screenid:onboarding.encouraging_flow.plan.benefits"
    }
  ]
};
