const translationKeyMapFirstBenefit: Record<string, string> = {
  "app.profunnel.motivation.question_screen.option.energy_vitality.confident_vibrant_golden_years_to": "app.profunnel.benefit_screen.benefit.increased_confidence_energy_levels",
  "app.profunnel.motivation.question_screen.option.energy_vitality.energy_for_hobbies": "app.profunnel.benefit_screen.benefit.more_energy_for_activities",
  "app.profunnel.motivation.question_screen.option.energy_vitality.energy_keep_up_kids": "app.profunnel.benefit_screen.benefit.more_energy_for_activities",
  "app.profunnel.motivation.question_screen.option.energy_vitality.energy_levels_high_to": "app.profunnel.benefit_screen.benefit.more_energy_for_activities",
  "app.profunnel.motivation.question_screen.option.energy_vitality.increase_strength_energy": "app.profunnel.benefit_screen.benefit.increased_strength_energy_levels",
  "app.profunnel.motivation.question_screen.option.energy_vitality.maintain_strength_energy": "app.profunnel.benefit_screen.benefit.increased_strength_energy_levels",
  "app.profunnel.motivation.question_screen.option.energy_vitality.more_energy_day": "app.profunnel.benefit_screen.benefit.increased_strength_energy_levels",
  "app.profunnel.motivation.question_screen.option.energy_vitality.more_energy_overall": "app.profunnel.benefit_screen.benefit.increased_strength_energy_levels",
  "app.profunnel.motivation.question_screen.option.health_wellness.be_healthy_prevent_diseases": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.cardio_health": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.feel_good_inside_out": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.improve_mobility_energy": "app.profunnel.benefit_screen.benefit.better_mobility_more_energy",
  "app.profunnel.motivation.question_screen.option.health_wellness.improve_overall_wellbeing": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.improving_overall_wellbeing": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.manage_chronic_diseases": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.manage_health_prevent_diseases": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.manage_stress_levels": "app.profunnel.benefit_screen.benefit.lower_stress_better_resilience",
  "app.profunnel.motivation.question_screen.option.health_wellness.prevent_diseases": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.recover_eating_disorder": "app.profunnel.benefit_screen.benefit.healthy_eating_habits",
  "app.profunnel.motivation.question_screen.option.health_wellness.recover_health_issues": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.stay_healthy_in_shape": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.health_wellness.stay_healthy_prevent_diseases": "app.profunnel.benefit_screen.benefit.increased_well_being_better_overall_health",
  "app.profunnel.motivation.question_screen.option.performance_fitness.athletic_performance": "app.profunnel.benefit_screen.benefit.increased_strength_energy_levels",
  "app.profunnel.motivation.question_screen.option.performance_fitness.be_fit_agile": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.be_more_athletic": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.being_more_athletic": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.fit_agile": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.get_in_shape": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.improve_strength": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.increase_fitness_level": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.independence_mobility": "app.profunnel.benefit_screen.benefit.increased_well_being_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.maintain_athletic_performance": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.maintain_fitness": "app.profunnel.benefit_screen.benefit.increased_well_being_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.stay_confident_shape": "app.profunnel.benefit_screen.benefit.increased_confidence_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.stay_fit_agile": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.stay_fit_hobbies": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.performance_fitness.stay_in_shape_active": "app.profunnel.benefit_screen.benefit.improved_physical_fitness",
  "app.profunnel.motivation.question_screen.option.self_confidence_appearance.boosting_confidence_to": "app.profunnel.benefit_screen.benefit.increased_confidence_well_being",
  "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_attractive": "app.profunnel.benefit_screen.benefit.feeling_attractive_good_in_own_skin",
  "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_fit_in_better": "app.profunnel.benefit_screen.benefit.increased_confidence_well_being",
  "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feel_more_confident": "app.profunnel.benefit_screen.benefit.increased_confidence_well_being",
  "app.profunnel.motivation.question_screen.option.self_confidence_appearance.feeling_proud_mirror_to": "app.profunnel.benefit_screen.benefit.feeling_good_in_own_skin",
  "app.profunnel.motivation.question_screen.option.self_confidence_appearance.fit_in_clothes_again": "app.profunnel.benefit_screen.benefit.wearing_what_you_want",
  "app.profunnel.motivation.question_screen.option.self_confidence_appearance.look_more_attractive": "app.profunnel.benefit_screen.benefit.feeling_attractive_good_in_own_skin",
  "app.profunnel.motivation.question_screen.option.social_family.active_engaged_family": "app.profunnel.benefit_screen.benefit.more_energy_for_activities",
  "app.profunnel.motivation.question_screen.option.social_family.example_family_grandkids": "app.profunnel.benefit_screen.benefit.good_role_model",
  "app.profunnel.motivation.question_screen.option.social_family.fit_active_family": "app.profunnel.benefit_screen.benefit.better_physical_fitness_overall_health",
  "app.profunnel.motivation.question_screen.option.social_family.keep_up_family_life": "app.profunnel.benefit_screen.benefit.better_physical_fitness_overall_health",
  "app.profunnel.motivation.question_screen.option.social_family.keep_up_grandkids": "app.profunnel.benefit_screen.benefit.better_physical_fitness_overall_health",
  "app.profunnel.motivation.question_screen.option.social_family.set_example_family_to": "app.profunnel.benefit_screen.benefit.good_role_model",
  "app.profunnel.motivation.question_screen.option.something_else": "app.profunnel.benefit_screen.benefit.increased_well_being_overall_health"
};

export function mapPersonalizedMotivationToFirstBenefit(key: string | undefined): string | undefined {
  if (!key) return;
  return translationKeyMapFirstBenefit[key];
}
