export function useOnboardingAnimatedCharacter(
  ageRange: Ref<"16-29" | "30-49" | "50-69" | "70+">,
  screenType: Ref<{ typeId: string }>,
) {
  const animatedCharacter = computed(() => {
    const character = {
      "16-29": "apple",
      "50-69": "beetroot",
      "70+": "broccoli",
      "30-49": "carrot",
    }[ageRange.value];

    return character as "apple" | "beetroot" | "broccoli" | "carrot";
  });

  const showAnimatedCharacter = computed(() => {
    return [
      "sex",
      "height",
      "current_weight",
      "target_weight",
      "birthday",
      "diet",
      "activity_level",
      "date",
      "single_choice",
      "multi_choice",
      "overall_goal",
      "days_in_row",
    ].includes(screenType.value.typeId);
  });

  return { animatedCharacter, showAnimatedCharacter };
}
