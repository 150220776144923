<script lang="ts" setup>
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { BaseScreen } from "~/types/onboarding/onboardingScreen";
import type { ActivityDegree, RegistrationParams } from "~/types/onboarding/registrationParams";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

interface Props {
  screen: BaseScreen;
  registrationParams: RegistrationParams;
  title: string;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue } = getOnboardingConditions(props);

interface Emits {
  (
    e: "next",
    value: {
      params: { activityDegree: ActivityDegree };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

function selectOption(activityDegree: ActivityDegree) {
  emit("next", {
    params: { activityDegree },
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}
</script>

<template>
  <div>
    <h1 v-if="title">
      {{ title }}
    </h1>
    <div class="mb-6">
      {{ $t("registration.activity.teaser") }}
    </div>
    <div class="grid gap-4">
      <OnboardingBtn
        emoji="🧑‍💻"
        translation-key="user.activity.option.low"
        description-translation-key="user.activity.description.low"
        @click="selectOption('low')"
      />
      <OnboardingBtn
        emoji="🧑‍🏫"
        translation-key="user.activity.option.normal"
        description-translation-key="user.activity.description.normal"
        @click="selectOption('moderate')"
      />
      <OnboardingBtn
        emoji="🧑‍💼"
        translation-key="user.activity.option.high"
        description-translation-key="user.activity.description.high"
        @click="selectOption('high')"
      />
      <OnboardingBtn
        emoji="👷"
        translation-key="user.activity.option.veryhigh"
        description-translation-key="user.activity.description.veryhigh"
        @click="selectOption('very_high')"
      />
    </div>
  </div>
</template>
