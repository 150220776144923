<script lang="ts" setup>
import { ref } from "vue";
import AppWeight from "~/components/app/AppWeight.vue";
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { StaticScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams, UnitMass } from "~/types/onboarding/registrationParams";
import { calculateBMI, calculateWeightForBmiAndHeight } from "~/utils/bmi";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

const MINIMUM_BMI: number = 17;
const MEDIUM_BMI: number = 19;

const { defaultUnitMass, t } = useNuxtApp().$i18n;

interface Props {
  screen: StaticScreen;
  registrationParams: RegistrationParams;
  title: string;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: {
        weightGoal: number;
        unitMass: UnitMass;
      };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();

const { getOrGetConditionalValue } = getOnboardingConditions(props);

const { kg, kgAsString, lbAsString, unitMass, validate, kgInvalid, lbInvalid, error, canContinue } = useWeight(
  props.registrationParams.weightGoal || null,
  props.registrationParams.unitMass || defaultUnitMass.value,
  true,
);
const showSkipAnyways = ref(false);

watch([kgAsString, lbAsString], () => {
  showSkipAnyways.value = false;
});

function next() {
  const hasError = validate();
  if (hasError) return;
  if (!kg.value) return;

  const bmi = calculateBMI(kg.value, props.registrationParams.bodyHeight);

  if (bmi > MINIMUM_BMI && bmi < MEDIUM_BMI && !showSkipAnyways.value) {
    const minimumBmiWeightInKg = calculateWeightForBmiAndHeight(MEDIUM_BMI, props.registrationParams.bodyHeight);
    error.value = t("registration.safety_check.goal_weight.borderline", [minimumBmiWeightInKg]);
    kgInvalid.value = true;
    lbInvalid.value = true;
    showSkipAnyways.value = true;
    return;
  } else if (bmi < MINIMUM_BMI) {
    error.value = t("registration.safety_check.goal_weight.too_low.headline");
    kgInvalid.value = true;
    lbInvalid.value = true;
    return;
  }

  emit("next", {
    params: {
      weightGoal: kg.value as number,
      unitMass: unitMass.value,
    },
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    with-mobile-fallback-button
    :button-disabled="!canContinue"
    :button-text="
      showSkipAnyways
        ? $t('registration.safety_check.goal_weight.too_low.proceed.button')
        : $t('registration.general.next')
    "
    @button-click="next"
  >
    <h1 v-if="title">
      {{ title }}
    </h1>
    <div class="mb-12">
      {{ $t("registration.goal_weight.teaser.info") }}
    </div>
    <AppWeight
      v-model:kg="kgAsString"
      v-model:lb="lbAsString"
      v-model:unitMass="unitMass"
      :kg-invalid="kgInvalid"
      :lb-invalid="lbInvalid"
      :error-message="error"
      @submit="next"
    />
  </OnboardingStickyButtonWrapper>
</template>
