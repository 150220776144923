<script lang="ts" setup>
import OnboardingStickyButtonWrapper from "~/components/onboarding/OnboardingStickyButtonWrapper.vue";
import type { RegistrationParams, UnitLength } from "~/types/onboarding/registrationParams";
import AppHeight from "~/components/app/AppHeight.vue";
import { useHeight } from "~/composables";
import type { StaticScreen } from "~/types/onboarding/onboardingScreen";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

interface Props {
  screen: StaticScreen;
  registrationParams: RegistrationParams;
  title: string;
}

const props = defineProps<Props>();

interface Emits {
  (
    e: "next",
    value: {
      params: {
        bodyHeight: number;
        unitLength: UnitLength;
      };
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();
const { getOrGetConditionalValue } = getOnboardingConditions(props);

const {
  cm,
  cmAsString,
  feetAsString,
  inchAsString,
  unitLength,
  validate,
  error,
  cmInvalid,
  feetInvalid,
  inchInvalid,
  canContinue,
} = useHeight(props.registrationParams.bodyHeight, props.registrationParams.unitLength);

function next() {
  const hasError = validate();
  if (hasError) return;

  emit("next", {
    params: {
      bodyHeight: cm.value as number,
      unitLength: unitLength.value,
    },
    nextScreenId: getOrGetConditionalValue(props.screen.nextStep),
  });
}
</script>

<template>
  <OnboardingStickyButtonWrapper
    :button-disabled="!canContinue"
    :button-text="$t('registration.general.next')"
    with-mobile-fallback-button
    @button-click="next"
  >
    <h1 v-if="title">
      {{ title }}
    </h1>
    <div class="mb-12">
      {{ $t("registration.height.info.teaser") }}
    </div>
    <AppHeight
      v-model:cm="cmAsString"
      v-model:feet="feetAsString"
      v-model:inch="inchAsString"
      v-model:unitLength="unitLength"
      :cm-invalid="cmInvalid"
      :ft-invalid="feetInvalid"
      :in-invalid="inchInvalid"
      :error-message="error"
      class="mb-4"
      @submit="next"
    />
  </OnboardingStickyButtonWrapper>
</template>
