<script setup lang="ts">
import { DotLottieVue, type DotLottie } from "@lottiefiles/dotlottie-vue";

type Props = {
  title: string;
  character: "apple" | "beetroot" | "broccoli" | "carrot";
};
const props = defineProps<Props>();

const lottieElement = ref();
let lottieInstance: DotLottie | null = null;

const segments = [
  [0, 300],
  [154, 300],
];
const segmentIndex = ref(1);
const segment = computed(() => segments[segmentIndex.value] as [number, number]);

const loop = ref(true);

onMounted(() => {
  lottieInstance = lottieElement.value.getDotLottieInstance();

  lottieInstance?.addEventListener("load", () => {
    lottieInstance?.setLayout({ fit: "fit-height" });
  });

  lottieInstance?.addEventListener("complete", () => {
    segmentIndex.value = 1;
    loop.value = true;
    lottieInstance?.play();
  });
});

function replay() {
  segmentIndex.value = 0;
  loop.value = false;
  lottieInstance?.stop();
  lottieInstance?.play();
}

defineExpose<{ replay: () => void }>({ replay });

const { assetBaseUrl } = useRuntimeConfig().public;

const lottieFile = computed(
  () =>
    ({
      apple: `${assetBaseUrl}/frontend/images/web-funnels/animation/apple-notebook.lottie`,
      beetroot: `${assetBaseUrl}/frontend/images/web-funnels/animation/beetroot-notebook.lottie`,
      broccoli: `${assetBaseUrl}/frontend/images/web-funnels/animation/broccoli-notebook.lottie`,
      carrot: `${assetBaseUrl}/frontend/images/web-funnels/animation/carrot-notebook.lottie`,
    })[props.character],
);

watch(lottieFile, () => {
  lottieInstance?.load({ src: lottieFile.value });
});
</script>

<template>
  <div class="flex items-center">
    <DotLottieVue
      ref="lottieElement"
      class="h-32 w-28"
      autoplay
      :loop="loop"
      :src="lottieFile"
      :segment="[...segment]"
      :use-frame-interpolation="true"
      :render-config="{ fit: 'fit-height' }"
    />
    <div v-if="title" class="flex-1">
      <div class="flex items-center">
        <svg width="20" viewBox="0 0 12 14" class="flex-none translate-x-1">
          <g transform="matrix(0 -1 1 0 -3.5 57) translate(0 0)" class="fill-delight-neutral-100">
            <path d="M 43 13 L 57 13 L 52 5 C 51 3 49 3 48 5 z" />
          </g>
          <g transform="matrix(0 -1 1 0 -3.5 57) translate(0 3)" class="fill-white">
            <path d="M 43 13 L 57 13 L 52 5 C 50 2 50 2 48 5 z" />
          </g>
        </svg>

        <div class="rounded-xl border-2 border-delight-neutral-100 bg-white p-4 font-medium text-delight-neutral-900">
          <transition name="fade" mode="out-in">
            <span :key="title" class="w-fit">
              {{ title }}
            </span>
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>
