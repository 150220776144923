<script lang="ts" setup>
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { onboardingPartner } from "~/resources/onboarding/onboarding-partner";
import { onboardingShort } from "~/resources/onboarding/onboarding-short";
import { onboardingTest } from "~/resources/onboarding/onboarding-test";
import { onboardingWelcome } from "~/resources/onboarding/onboarding-welcome";
import { onboardingCalculator } from "~/resources/onboarding/onboarding-calculator";
import { useOnboardingStore } from "~/stores/useOnboardingStore";
import AppProgress from "~/components/app/AppProgress.vue";
import type { Instructions } from "~/types/onboarding/onboardingScreen";
import { stripScreenIdPrefix } from "~/utils/text";
import LottieOnboardingHeader from "~/components/animations/LottieOnboardingHeader.vue";

const { enabledFunnels } = useAppConfig();
const { t } = useNuxtApp().$i18n;
const {
  app: { baseURL },
} = useRuntimeConfig();

useHead({
  title: t("app.prosignup.seo_title"),
});

definePageMeta({
  key: "onboarding",
  middleware: ["redirect-authenticated-users", "check-calculator-parameters", "skip-onboarding"],
});

const onboardingStore = useOnboardingStore();
const route = useRoute();
const { addImpressionEvent } = useTrackingStore();
const { skippedOnboarding, singleChoiceSelectedOptions } = storeToRefs(onboardingStore);
const lottieComponent = ref();
const featureFlags = useFeatureFlags();

const funnels: Record<string, Instructions> = {
  partner: onboardingPartner,
  start: onboardingShort,
  test: onboardingTest,
  welcome: onboardingWelcome,
  calculator: onboardingCalculator,
};

const defaultInstructionsIdentifier = "start";
const funnelName = computed(() => {
  const requested: string = Array.isArray(route.params.instructions)
    ? route.params.instructions[0]
    : route.params.instructions;

  if (!requested || requested === defaultInstructionsIdentifier) {
    return { requested, valid: defaultInstructionsIdentifier };
  }

  if (!enabledFunnels.includes(requested) || !funnels[requested]) {
    return { requested, valid: defaultInstructionsIdentifier };
  }

  return { requested, valid: requested };
});

if (
  funnelName.value.requested !== funnelName.value.valid &&
  !["", defaultInstructionsIdentifier].includes(funnelName.value.requested)
) {
  await navigateTo("/onboarding");
}

const {
  updateRegistrationParams,
  screen,
  screenComponent,
  registrationParams,
  screenShouldBeReplaced,
  screenHasWideWrapper,
  screenUseTransparentBackground,
  getProgress,
  title,
  screenType,
} = useOnboarding(funnels[funnelName.value.valid]);

async function doNext({ nextScreenId, params }: { nextScreenId: string; params: Partial<RegistrationParams> }) {
  const currentScreenId = screen.value.id;
  const replace = screenShouldBeReplaced(currentScreenId);

  if (
    currentScreenId === "screenid:onboarding.encouraging_flow.personal.personalized_question" &&
    nextScreenId !== "screenid:onboarding.encouraging_flow.plan.load_plan_1" &&
    featureFlags.skip_motivation_affirmation_screens.isOn().value
  ) {
    nextScreenId = "screenid:onboarding.encouraging_flow.plan.load_plan_1";
  }

  updateRegistrationParams(params);

  lottieComponent.value?.replay();

  if (nextScreenId === "screenid:end") {
    updateRegistrationParams({ incomplete: false });

    return navigateTo({
      path: "/onboarding/checkout",
      query: {
        instructions: route.params.instructions || defaultInstructionsIdentifier,
      },
      replace,
    });
  }

  const nextScreenSlug =
    Object.keys(onboardingScreenSlugs).find((key) => onboardingScreenSlugs[key] === nextScreenId) || nextScreenId;

  await navigateTo({
    path: `/onboarding/${route.params.instructions || defaultInstructionsIdentifier}/${nextScreenSlug}`,
    replace,
  });
}

function trackScreenImpression() {
  addImpressionEvent({ name: stripScreenIdPrefix(screen.value.id), webFunnelId: funnelName.value.valid });
}

onMounted(() => {
  trackScreenImpression();
});

watch(
  () => screen.value.id,
  () => {
    trackScreenImpression();
  },
);

async function skip() {
  registrationParams.value = onboardingStore.getDefaultPartialRegistrationParams();
  skippedOnboarding.value = true;

  await navigateTo(`${baseURL}/onboarding/checkout`, { external: true });
}

const { animatedCharacter, showAnimatedCharacter } = useOnboardingAnimatedCharacter(
  computed(() => singleChoiceSelectedOptions.value["age-range"] as "16-29" | "30-49" | "50-69" | "70+"),
  screenType,
);

const showAnimatedHeader = computed(
  () => showAnimatedCharacter.value && featureFlags.animated_fruits_questions.isOn().value,
);
</script>

<template>
  <transition name="fade" mode="out-in">
    <div
      :key="screenHasWideWrapper.toString()"
      class="flex flex-1 flex-col"
      :class="{
        'bg-mobile-blurred-background bg-cover bg-top sm:bg-desktop-blurred-background': screenUseTransparentBackground,
      }"
    >
      <div class="flex flex-1 flex-col">
        <div
          class="flex flex-1 justify-center"
          :class="[screenHasWideWrapper ? 'mx-auto w-full pb-4' : 'container px-bs-container']"
        >
          <section
            class="flex flex-col pb-5"
            :class="[screenHasWideWrapper ? 'w-full max-w-full' : 'w-full max-w-full lg:w-7/12']"
          >
            <AppProgress
              v-if="!screenUseTransparentBackground && !screenHasWideWrapper"
              :progress="getProgress()"
              class="mt-5"
              @skip="skip"
            />
            <transition name="fade" mode="out-in">
              <LottieOnboardingHeader
                v-if="showAnimatedHeader"
                ref="lottieComponent"
                :title="title"
                :character="animatedCharacter"
                class="my-4"
              />
            </transition>

            <transition name="fade" mode="out-in">
              <component
                :is="screenComponent"
                :screen="screen"
                :registration-params="registrationParams"
                :has-wide-wrapper="screenHasWideWrapper"
                :title="showAnimatedHeader ? '' : title"
                class="flex-1"
                @next="doNext"
                @skip="skip"
              />
            </transition>
          </section>
        </div>
      </div>
    </div>
  </transition>
</template>
