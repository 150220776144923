<script setup lang="ts">
import { match } from "ts-pattern";
import type { Directive } from "vue";
import Text from "~/components/atoms/Text.vue";

const { t } = useNuxtApp().$i18n;
const calculatorFunnelStore = useCalculatorFunnelStore();

const displayData = computed<
  | {
      header: string;
      value: string;
      text?: string;
      recommendation: string;
    }
  | undefined
>(() => {
  const calculatorData = calculatorFunnelStore.getCalculatorData();

  if (!calculatorData) {
    return undefined;
  }

  if (calculatorData.type === "bmi") {
    const recommendation = match(calculatorData.category)
      .with("underweight", () =>
        t("app.profunnel.calculator_funnel.bmi.recommendation.underweight", [
          `${calculatorData.idealMin} ${calculatorData.weightUnit}`,
          `${calculatorData.idealMax} ${calculatorData.weightUnit}`,
        ]),
      )
      .with("healthy", () =>
        t("app.profunnel.calculator_funnel.bmi.recommendation.healthy", [
          `${calculatorData.idealMin} ${calculatorData.weightUnit}`,
          `${calculatorData.idealMax} ${calculatorData.weightUnit}`,
        ]),
      )
      .with("overweight", () =>
        t("app.profunnel.calculator_funnel.bmi.recommendation.overweight", [
          `${calculatorData.idealMin} ${calculatorData.weightUnit}`,
          `${calculatorData.idealMax} ${calculatorData.weightUnit}`,
        ]),
      )
      .with("obese", () =>
        t("app.profunnel.calculator_funnel.bmi.recommendation.obese", [
          `${calculatorData.idealMin} ${calculatorData.weightUnit}`,
          `${calculatorData.idealMax} ${calculatorData.weightUnit}`,
        ]),
      )
      .with("morbidly_obese", () =>
        t("app.profunnel.calculator_funnel.bmi.recommendation.morbidly_obese", [
          `${calculatorData.idealMin} ${calculatorData.weightUnit}`,
          `${calculatorData.idealMax} ${calculatorData.weightUnit}`,
        ]),
      )
      .exhaustive();

    const text = match(calculatorData.category)
      .with("underweight", () => t("app.profunnel.calculator_funnel.bmi.result.underweight"))
      .with("healthy", () => t("app.profunnel.calculator_funnel.bmi.result.healthy"))
      .with("overweight", () => t("app.profunnel.calculator_funnel.bmi.result.overweight"))
      .with("obese", () => t("app.profunnel.calculator_funnel.bmi.result.obese"))
      .with("morbidly_obese", () => t("app.profunnel.calculator_funnel.bmi.result.morbidly_obese"))
      .exhaustive();

    return {
      header: t("app.profunnel.calculator_funnel.bmi.header"),
      value: calculatorData.value,
      text,
      recommendation,
    };
  }

  if (calculatorData.type === "dailycalories") {
    return {
      header: t("app.profunnel.calculator_funnel.calorie_intake.header"),
      text: t("app.profunnel.calculator_funnel.calorie_intake.result"),
      value: `${calculatorData.min} - ${calculatorData.max} kcal`,
      recommendation: t("app.profunnel.calculator_funnel.calorie_intake.recommendation"),
    };
  }

  if (calculatorData.type === "idealweight") {
    return {
      header: t("app.profunnel.calculator_funnel.ideal_weight.header"),
      value: `${calculatorData.min} - ${calculatorData.max} ${calculatorData.weightUnit}`,
      recommendation: t("app.profunnel.calculator_funnel.ideal_weight.recommendation"),
    };
  }
});

const vCustomHtml: Directive = {
  mounted: (el: HTMLDivElement, binding) => (el.innerHTML = binding.value),
};
</script>

<template>
  <div v-if="displayData" class="overflow-hidden rounded-xl border border-solid border-black/10">
    <div class="flex items-center justify-between rounded-t-xl bg-yz-teal-100 p-4">
      <Text bold size="medium" class="text-gray-700">{{ displayData.header }}</Text>
      <Text bold size="heading" class="text-yz-teal-600">{{ displayData.value }}</Text>
    </div>

    <div class="flex flex-col bg-white p-4">
      <template v-if="displayData.text">
        <Text v-custom-html="displayData.text" size="medium" class="text-gray-700"></Text>
        <hr class="m-3 border text-gray-200 opacity-100" />
      </template>
      <Text
        class="text-gray-500"
        size="small"
        bold
        translation-key="app.profunnel.calculator_funnel.general.recommendation.heading"
      ></Text>
      <Text v-custom-html="displayData.recommendation" class="text-gray-500" size="small"></Text>
    </div>
  </div>
</template>
