import type { Instructions } from "~/types/onboarding/onboardingScreen";

/* eslint quote-props: ["error", "always"] */
export const onboardingShort: Instructions = {
  "start": {
    "conditions": [],
    "fallback": "screenid:onboarding.encouraging_flow.personal.age_range"
  },
  "screens": [
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.gender"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.age_range",
      "staticScreenType": "age_range"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.goal.main_goal"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.gender",
      "staticScreenType": "sex"
    },
    {
      "type": "overall_goal",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.goal.main_goal.question"
      },
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.current_weight"
      },
      "id": "screenid:onboarding.encouraging_flow.goal.main_goal",
      "showElseOption": true
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal_goal.goal_weight"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.current_weight",
      "staticScreenType": "current_weight"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.height"
      },
      "id": "screenid:onboarding.encouraging_flow.personal_goal.goal_weight",
      "staticScreenType": "target_weight"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.birthday"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.height",
      "staticScreenType": "height"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.personalized_question"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.birthday",
      "staticScreenType": "birthday"
    },
    {
      "type": "single_choice",
      "generatorFunctionName": "personalizedMotivationScreen",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.self_confidence_appearance.heading"
      },
      "options": [],
      "id": "screenid:onboarding.encouraging_flow.personal.personalized_question"
    },
    {
      "type": "affirmation",
      "id": "screenid:app.profunnel.motivation.affirmation_screen.self_confidence_appearance",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.self_confidence_appearance.heading"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.self_confidence_appearance.subheading"
      },
      "imageSize": "LARGE",
      "imageAlignment": "CENTER",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/frontend/web-funnels/onboarding/illustrations/motivation-self-confidence-appearance"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:app.profunnel.motivation.affirmation_screen.health_wellness",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.health_wellness.heading"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.health_wellness.subheading"
      },
      "imageSize": "LARGE",
      "imageAlignment": "CENTER",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/frontend/web-funnels/onboarding/illustrations/motivation-health-wellness"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:app.profunnel.motivation.affirmation_screen.energy_vitality",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.energy_vitality.heading"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.energy_vitality.subheading"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/frontend/web-funnels/onboarding/illustrations/motivation-energy-vitality"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:app.profunnel.motivation.affirmation_screen.performance_fitness",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.social_family.heading"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.performance_fitness.subheading"
      },
      "imageSize": "LARGE",
      "imageAlignment": "CENTER",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/frontend/web-funnels/onboarding/illustrations/motivation-performance-fitness"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:app.profunnel.motivation.affirmation_screen.social_family",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.social_family.heading"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.motivation.affirmation_screen.social_family.subheading"
      },
      "imageSize": "LARGE",
      "imageAlignment": "CENTER",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/frontend/web-funnels/onboarding/illustrations/motivation-social-family"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
      }
    },
    {
      "type": "prepare_plan",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": ""
      },
      "steps": [],
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.benefits"
      },
      "id": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
    },
    {
      "type": "static",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.benefit_screen.heading.what_you_can_look_forward_to"
      },
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:end"
      },
      "staticScreenType": "benefits",
      "id": "screenid:onboarding.encouraging_flow.plan.benefits"
    }
  ]
};
