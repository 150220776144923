import type { Instructions } from "~/types/onboarding/onboardingScreen";

/* eslint quote-props: ["error", "always"] */
export const onboardingWelcome: Instructions = {
  "start": {
    "conditions": [],
    "fallback": "screenid:onboarding.encouraging_flow.personal.age_range"
  },
  "screens": [
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.gender"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.age_range",
      "staticScreenType": "age_range"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.goal.main_goal"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.gender",
      "staticScreenType": "sex"
    },
    {
      "type": "overall_goal",
      "id": "screenid:onboarding.encouraging_flow.goal.main_goal",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.goal.main_goal.question"
      },
      "nextStep": {
        "conditions": [
          {
            "condition": "GoalMaintainWeight",
            "value": "screenid:onboarding.encouraging_flow.short.start_journey_affirmation"
          },
          {
            "condition": "GoalElse",
            "value": "screenid:onboarding.encouraging_flow.short.start_journey_affirmation"
          }
        ],
        "fallback": "screenid:onboarding.encouraging_flow.goal.reason"
      },
      "showElseOption": true
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.goal.reason",
      "titleTranslationKey": {
        "conditions": [
          {
            "condition": "GoalGainWeight",
            "value": "onboarding.encouraging_flow.gain_weight.goal.reason.question"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "onboarding.encouraging_flow.build_muscle.goal.reason.question"
          }
        ],
        "fallback": "onboarding.encouraging_flow.goal.reason.question"
      },
      "options": [
        {
          "emoji": "😎",
          "translationKey": "onboarding.encouraging_flow.goal.reason.confidence",
          "trackingName": "onboarding.encouraging_flow.goal.reason.confidence",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.goal.additional_goal"
          }
        },
        {
          "emoji": "💚",
          "translationKey": "onboarding.encouraging_flow.goal.reason.health",
          "trackingName": "onboarding.encouraging_flow.goal.reason.health",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.goal.additional_goal"
          }
        },
        {
          "emoji": "🏋️",
          "translationKey": "onboarding.encouraging_flow.goal.reason.fitness",
          "trackingName": "onboarding.encouraging_flow.goal.reason.fitness",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.goal.additional_goal"
          }
        },
        {
          "emoji": "🎊",
          "translationKey": "onboarding.encouraging_flow.goal.reason.event",
          "trackingName": "onboarding.encouraging_flow.goal.reason.event",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.goal.additional_goal"
          }
        },
        {
          "emoji": "📉",
          "translationKey": "onboarding.encouraging_flow.build_muscle.goal.reason.other",
          "trackingName": "onboarding.encouraging_flow.build_muscle.goal.reason.other",
          "visible": {
            "conditions": [
              {
                "condition": "GoalBuildMuscle",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.goal.additional_goal"
          }
        },
        {
          "emoji": "🍀",
          "translationKey": "onboarding.encouraging_flow.gain_weight.goal.reason.other",
          "trackingName": "onboarding.encouraging_flow.gain_weight.goal.reason.other",
          "visible": {
            "conditions": [
              {
                "condition": "GoalGainWeight",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.goal.additional_goal"
          }
        },
        {
          "emoji": "💬",
          "translationKey": "onboarding.encouraging_flow.goal.reason.other",
          "trackingName": "onboarding.encouraging_flow.goal.reason.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.goal.additional_goal"
          }
        }
      ]
    },
    {
      "type": "multi_choice",
      "id": "screenid:onboarding.encouraging_flow.goal.additional_goal",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.goal.additional_goal.question"
      },
      "options": [
        {
          "emoji": "🍲",
          "translationKey": "onboarding.encouraging_flow.goal.additional_goal.food",
          "trackingName": "onboarding.encouraging_flow.goal.additional_goal.food",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🧑‍🍳",
          "translationKey": "onboarding.encouraging_flow.goal.additional_goal.cooking",
          "trackingName": "onboarding.encouraging_flow.goal.additional_goal.cooking",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🍋",
          "translationKey": "onboarding.encouraging_flow.goal.additional_goal.immune_system",
          "trackingName": "onboarding.encouraging_flow.goal.additional_goal.immune_system",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "💤",
          "translationKey": "onboarding.encouraging_flow.goal.additional_goal.sleep",
          "trackingName": "onboarding.encouraging_flow.goal.additional_goal.sleep",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🫶",
          "translationKey": "onboarding.encouraging_flow.goal.additional_goal.feel_good",
          "trackingName": "onboarding.encouraging_flow.goal.additional_goal.feel_good",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "💬",
          "translationKey": "onboarding.encouraging_flow.goal.additional_goal.other",
          "trackingName": "onboarding.encouraging_flow.goal.additional_goal.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "requireAdditionalAnswer": true
        }
      ],
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [
          {
            "condition": "GoalGainWeight",
            "value": "screenid:onboarding.encouraging_flow_gain_weight.past.experience"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "screenid:onboarding.encouraging_flow_build_muscle.past.experience"
          }
        ],
        "fallback": "screenid:onboarding.encouraging_flow.past.experience"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.past.experience",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.past.experience.question"
      },
      "options": [
        {
          "emoji": "↘️",
          "translationKey": "onboarding.encouraging_flow.past.experience.successful",
          "trackingName": "onboarding.encouraging_flow.past.experience.successful",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.diets.restrictive"
          }
        },
        {
          "emoji": "➡️",
          "translationKey": "onboarding.encouraging_flow.past.experience.unsuccessful",
          "trackingName": "onboarding.encouraging_flow.past.experience.unsuccessful",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.diets.restrictive"
          }
        },
        {
          "emoji": "↔️",
          "translationKey": "onboarding.encouraging_flow.past.experience.gained_back",
          "trackingName": "onboarding.encouraging_flow.past.experience.gained_back",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.diets.restrictive"
          }
        },
        {
          "emoji": "💡",
          "translationKey": "onboarding.encouraging_flow.past.experience.first_attempt",
          "trackingName": "onboarding.encouraging_flow.past.experience.first_attempt",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.role_model"
          }
        }
      ]
    },
    {
      "type": "why_other_diets_fail",
      "id": "screenid:onboarding.encouraging_flow.diets.restrictive",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.diets.sustainable"
      },
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.diets.restrictive.title"
      },
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.yo-yo-effect"
      },
      "nextButtonTranslationKey": "system.general.button.continue",
      "infoList": [
        {
          "type": "why_other_diets_fail_item_logo",
          "logo": "columbia",
          "translationKey": "onboarding.encouraging_flow.yo_yo_effect.reference.columbia_university"
        },
        {
          "type": "why_other_diets_fail_item_logo",
          "logo": "aasd",
          "translationKey": "onboarding.encouraging_flow.yo_yo_effect.reference.asian_association"
        }
      ]
    },
    {
      "type": "why_other_diets_fail",
      "id": "screenid:onboarding.encouraging_flow.diets.sustainable",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.past.challenges"
      },
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.diets.sustainable.title"
      },
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.yazio-vs-restrictive-diets"
      },
      "nextButtonTranslationKey": "system.general.button.continue",
      "infoList": [
        {
          "type": "why_other_diets_fail_item_emoji",
          "emoji": "🙌",
          "translationKey": "onboarding.encouraging_flow.diets.sustainable.affirmation_no_rules"
        },
        {
          "type": "why_other_diets_fail_item_emoji",
          "emoji": "🍏",
          "translationKey": "onboarding.encouraging_flow.diets.sustainable.affirmation_weight_loss"
        }
      ]
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow_build_muscle.past.experience",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.build_muscle.past.experience.question"
      },
      "options": [
        {
          "emoji": "↗️",
          "translationKey": "onboarding.encouraging_flow.build_muscle.past.experience.successful",
          "trackingName": "onboarding.encouraging_flow.build_muscle.past.experience.successful",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.challenges"
          }
        },
        {
          "emoji": "➡️",
          "translationKey": "onboarding.encouraging_flow.build_muscle.past.experience.unsuccessful",
          "trackingName": "onboarding.encouraging_flow.build_muscle.past.experience.unsuccessful",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.challenges"
          }
        },
        {
          "emoji": "↔️",
          "translationKey": "onboarding.encouraging_flow.build_muscle.past.experience.lost_again",
          "trackingName": "onboarding.encouraging_flow.build_muscle.past.experience.lost_again",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.challenges"
          }
        },
        {
          "emoji": "💡",
          "translationKey": "onboarding.encouraging_flow.build_muscle.past.experience.first_attempt",
          "trackingName": "onboarding.encouraging_flow.build_muscle.past.experience.first_attempt",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.role_model"
          }
        }
      ]
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow_gain_weight.past.experience",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.gain_weight.past.experience.question"
      },
      "options": [
        {
          "emoji": "↗️",
          "translationKey": "onboarding.encouraging_flow.gain_weight.past.experience.successful",
          "trackingName": "onboarding.encouraging_flow.gain_weight.past.experience.successful",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.challenges"
          }
        },
        {
          "emoji": "➡️",
          "translationKey": "onboarding.encouraging_flow.gain_weight.past.experience.unsuccessful",
          "trackingName": "onboarding.encouraging_flow.gain_weight.past.experience.unsuccessful",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.challenges"
          }
        },
        {
          "emoji": "↔️",
          "translationKey": "onboarding.encouraging_flow.gain_weight.past.experience.lost_again",
          "trackingName": "onboarding.encouraging_flow.gain_weight.past.experience.lost_again",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.challenges"
          }
        },
        {
          "emoji": "💡",
          "translationKey": "onboarding.encouraging_flow.gain_weight.past.experience.first_attempt",
          "trackingName": "onboarding.encouraging_flow.gain_weight.past.experience.first_attempt",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.role_model"
          }
        }
      ]
    },
    {
      "type": "multi_choice",
      "id": "screenid:onboarding.encouraging_flow.past.challenges",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.past.challenges.question"
      },
      "options": [
        {
          "emoji": "🍟",
          "translationKey": "onboarding.encouraging_flow.past.challenges.cravings",
          "trackingName": "onboarding.encouraging_flow.past.challenges.cravings",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "emoji": "📈",
          "translationKey": "onboarding.encouraging_flow.gain_weight.past.challenges.replenish",
          "trackingName": "onboarding.encouraging_flow.gain_weight.past.challenges.replenish",
          "visible": {
            "conditions": [
              {
                "condition": "GoalGainWeight",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "emoji": "✨",
          "translationKey": "onboarding.encouraging_flow.past.challenges.motivation",
          "trackingName": "onboarding.encouraging_flow.past.challenges.motivation",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🥣",
          "translationKey": "onboarding.encouraging_flow.past.challenges.portions",
          "trackingName": "onboarding.encouraging_flow.past.challenges.portions",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "emoji": "🥣",
          "translationKey": "onboarding.encouraging_flow.past.challenges.quality_foods",
          "trackingName": "onboarding.encouraging_flow.past.challenges.quality_foods",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": false
              }
            ],
            "fallback": true
          }
        },
        {
          "emoji": "💪",
          "translationKey": "onboarding.encouraging_flow.past.challenges.protein",
          "trackingName": "onboarding.encouraging_flow.past.challenges.protein",
          "visible": {
            "conditions": [
              {
                "condition": "GoalBuildMuscle",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "emoji": "🥗",
          "translationKey": "onboarding.encouraging_flow.past.challenges.food",
          "trackingName": "onboarding.encouraging_flow.past.challenges.food",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "⏰",
          "translationKey": "onboarding.encouraging_flow.past.challenges.busy",
          "trackingName": "onboarding.encouraging_flow.past.challenges.busy",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "💬",
          "translationKey": "onboarding.encouraging_flow.past.challenges.other",
          "trackingName": "onboarding.encouraging_flow.past.challenges.other",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.past.challenges_affirmation"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.past.challenges_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.past.challenges_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [
          {
            "condition": "GoalGainWeight",
            "value": "onboarding.encouraging_flow.gain_weight.past.challenges_affirmation.description"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "onboarding.encouraging_flow.build_muscle.past.challenges_affirmation.description"
          }
        ],
        "fallback": "onboarding.encouraging_flow.past.challenges_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/welcome_greetings"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.past.gain_causes"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.past.gain_causes",
      "titleTranslationKey": {
        "conditions": [
          {
            "condition": "GoalGainWeight",
            "value": "onboarding.encouraging_flow.gain_weight.past.gain_causes.question"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "onboarding.encouraging_flow.build_muscle.past.gain_causes.question"
          }
        ],
        "fallback": "onboarding.encouraging_flow.past.gain_causes.question"
      },
      "options": [
        {
          "emoji": "🩹",
          "translationKey": "onboarding.encouraging_flow.past.gain_causes.injury",
          "trackingName": "onboarding.encouraging_flow.past.gain_causes.injury",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.changes"
          }
        },
        {
          "emoji": "💼",
          "translationKey": "onboarding.encouraging_flow.past.gain_causes.personal_life",
          "trackingName": "onboarding.encouraging_flow.past.gain_causes.personal_life",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.changes"
          }
        },
        {
          "emoji": "🤰",
          "translationKey": "onboarding.encouraging_flow.past.gain_causes.pregnancy",
          "trackingName": "onboarding.encouraging_flow.past.gain_causes.pregnancy",
          "visible": {
            "conditions": [
              {
                "condition": "SexMale",
                "value": false
              }
            ],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.changes"
          }
        },
        {
          "emoji": "🔄",
          "translationKey": "onboarding.encouraging_flow.past.gain_causes.metabolism",
          "trackingName": "onboarding.encouraging_flow.past.gain_causes.metabolism",
          "visible": {
            "conditions": [
              {
                "condition": "GoalGainWeight",
                "value": false
              }
            ],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.changes"
          }
        },
        {
          "emoji": "🔄",
          "translationKey": "onboarding.encouraging_flow.gain_weight.past.gain_causes.metabolism",
          "trackingName": "onboarding.encouraging_flow.gain_weight.past.gain_causes.metabolism",
          "visible": {
            "conditions": [
              {
                "condition": "GoalGainWeight",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.changes"
          }
        },
        {
          "emoji": "🤯",
          "translationKey": "onboarding.encouraging_flow.past.gain_causes.mental_health",
          "trackingName": "onboarding.encouraging_flow.past.gain_causes.mental_health",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.changes"
          }
        },
        {
          "emoji": "💊",
          "translationKey": "onboarding.encouraging_flow.past.gain_causes.medication",
          "trackingName": "onboarding.encouraging_flow.past.gain_causes.medication",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.changes"
          }
        },
        {
          "emoji": "💬",
          "translationKey": "onboarding.encouraging_flow.past.gain_causes.other",
          "trackingName": "onboarding.encouraging_flow.past.gain_causes.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "requireAdditionalAnswer": true,
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.past.changes"
          }
        }
      ]
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.past.changes",
      "titleTranslationKey": {
        "conditions": [
          {
            "condition": "GoalGainWeight",
            "value": "welcome_back_gain_weight.past_changes.question"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "onboarding.encouraging_flow.build_muscle.past.changes.question"
          }
        ],
        "fallback": "onboarding.encouraging_flow.past.changes.question"
      },
      "options": [
        {
          "emoji": "👍",
          "translationKey": "system.general.button.yes",
          "trackingName": "system.general.button.yes",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.differences"
          }
        },
        {
          "emoji": "👎",
          "translationKey": "system.general.button.no",
          "trackingName": "system.general.button.no",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.role_model"
          }
        }
      ],
      "optionsLayout": "Grid"
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.changes.differences",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.changes.differences.question"
      },
      "options": [
        {
          "emoji": "🧠",
          "translationKey": "onboarding.encouraging_flow.changes.differences.mindset",
          "trackingName": "onboarding.encouraging_flow.changes.differences.mindset",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.come_back_affirmation"
          }
        },
        {
          "emoji": "📝",
          "translationKey": "onboarding.encouraging_flow.changes.differences.plan",
          "trackingName": "onboarding.encouraging_flow.changes.differences.plan",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.come_back_affirmation"
          }
        },
        {
          "emoji": "🧹",
          "translationKey": "onboarding.encouraging_flow.changes.differences.personal_changes",
          "trackingName": "onboarding.encouraging_flow.changes.differences.personal_changes",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.come_back_affirmation"
          }
        },
        {
          "emoji": "🧘",
          "translationKey": "onboarding.encouraging_flow.changes.differences.health",
          "trackingName": "onboarding.encouraging_flow.changes.differences.health",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.come_back_affirmation"
          }
        },
        {
          "emoji": "🚀",
          "translationKey": "onboarding.encouraging_flow.changes.differences.motivation",
          "trackingName": "onboarding.encouraging_flow.changes.differences.motivation",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.come_back_affirmation"
          }
        },
        {
          "emoji": "💬",
          "translationKey": "onboarding.encouraging_flow.changes.differences.other",
          "trackingName": "onboarding.encouraging_flow.changes.differences.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "requireAdditionalAnswer": true,
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.come_back_affirmation"
          }
        }
      ]
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.changes.come_back_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.changes.come_back_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.changes.come_back_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/progress_success"
      },
      "nextButtonTranslationKey": "system.general.button.ive_got_this",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.changes.role_model"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.changes.role_model",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.changes.role_model.question"
      },
      "options": [
        {
          "emoji": "✊",
          "translationKey": "onboarding.encouraging_flow.changes.role_model.willpower",
          "trackingName": "onboarding.encouraging_flow.changes.role_model.willpower",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.success_factors_affirmation"
          }
        },
        {
          "emoji": "📐",
          "translationKey": "onboarding.encouraging_flow.changes.role_model.structure",
          "trackingName": "onboarding.encouraging_flow.changes.role_model.structure",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.success_factors_affirmation"
          }
        },
        {
          "emoji": "🍎",
          "translationKey": "onboarding.encouraging_flow.changes.role_model.habits",
          "trackingName": "onboarding.encouraging_flow.changes.role_model.habits",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.success_factors_affirmation"
          }
        },
        {
          "emoji": "🤝",
          "translationKey": "onboarding.encouraging_flow.changes.role_model.support",
          "trackingName": "onboarding.encouraging_flow.changes.role_model.support",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.success_factors_affirmation"
          }
        },
        {
          "emoji": "❓",
          "translationKey": "onboarding.encouraging_flow.changes.role_model.other",
          "trackingName": "onboarding.encouraging_flow.changes.role_model.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "requireAdditionalAnswer": true,
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.changes.success_factors_affirmation"
          }
        }
      ]
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.changes.success_factors_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.changes.success_factors_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.changes.success_factors_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.changes.success_factors_affirmation"
      },
      "nextButtonTranslationKey": "system.general.button.i_cant_wait",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.experience"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.experience",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.experience.question"
      },
      "options": [
        {
          "emoji": "👍",
          "translationKey": "system.general.button.yes",
          "trackingName": "system.general.button.yes",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yes_affirmation"
          }
        },
        {
          "emoji": "👎",
          "translationKey": "system.general.button.no",
          "trackingName": "system.general.button.no",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.info"
          }
        }
      ],
      "optionsLayout": "Grid"
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.yes_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.yes_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [
          {
            "condition": "GoalLooseWeight",
            "value": "onboarding.encouraging_flow.calorie_counting.yes_affirmation.description"
          }
        ],
        "fallback": "onboarding.encouraging_flow.calorie_counting.yes_affirmation.description_alternative"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/look_forward"
      },
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.past"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.past",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.past.question"
      },
      "options": [
        {
          "emoji": "📱",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.past.app",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.past.app",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.app_choice"
          }
        },
        {
          "emoji": "🖥️",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.past.website",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.past.website",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.app_affirmation"
          }
        },
        {
          "emoji": "✏️",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.past.paper",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.past.paper",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.app_affirmation"
          }
        },
        {
          "emoji": "🔢",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.past.spreadsheet",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.past.spreadsheet",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.app_affirmation"
          }
        },
        {
          "emoji": "📟",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.past.calculator",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.past.calculator",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.app_affirmation"
          }
        },
        {
          "emoji": "🧠",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.past.mental_calculation",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.past.mental_calculation",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.app_affirmation"
          }
        },
        {
          "emoji": "💬",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.past.other",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.past.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "requireAdditionalAnswer": true,
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.app_affirmation"
          }
        }
      ]
    },
    {
      "type": "info_list",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.app_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.app_affirmation.title"
      },
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/calorie_counting_app"
      },
      "infoList": [
        {
          "titleTranslationKey": "onboarding.encouraging_flow.calorie_counting.app_affirmation.database",
          "emoji": "🌐",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.calorie_counting.app_affirmation.barcode_scanner",
          "emoji": "📷",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.calorie_counting.app_affirmation.progress",
          "emoji": "📈",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.calorie_counting.app_affirmation.overview",
          "emoji": "📊",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.count_me_in",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.difficult"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.app_choice",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.app_choice.question"
      },
      "options": [
        {
          "emoji": "🔶",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.app_choice.yazio",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.app_choice.yazio",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.difficult"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.app_choice.mfp",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.app_choice.mfp",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.app_choice.lifesum",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.app_choice.lifesum",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.app_choice.loseit",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.app_choice.loseit",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.build_muscle.calorie_counting.app_choice.strongr_fastr",
          "trackingName": "onboarding.encouraging_flow.build_muscle.calorie_counting.app_choice.strongr_fastr",
          "visible": {
            "conditions": [
              {
                "condition": "GoalBuildMuscle",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.build_muscle.calorie_counting.app_choice.bulk",
          "trackingName": "onboarding.encouraging_flow.build_muscle.calorie_counting.app_choice.bulk",
          "visible": {
            "conditions": [
              {
                "condition": "GoalBuildMuscle",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.app_choice.ww",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.app_choice.ww",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.app_choice.noom",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.app_choice.noom",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.app_choice.fatsecret",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.app_choice.fatsecret",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.build_muscle.calorie_counting.app_choice.strongr_fastr",
          "trackingName": "onboarding.encouraging_flow.build_muscle.calorie_counting.app_choice.strongr_fastr",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": false
              }
            ],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.build_muscle.calorie_counting.app_choice.bulk",
          "trackingName": "onboarding.encouraging_flow.build_muscle.calorie_counting.app_choice.bulk",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": false
              }
            ],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "🔷",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.app_choice.simple",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.app_choice.simple",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": false
              }
            ],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        },
        {
          "emoji": "💬",
          "translationKey": "onboarding.encouraging_flow.calorie_counting.app_choice.other",
          "trackingName": "onboarding.encouraging_flow.calorie_counting.app_choice.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation"
          }
        }
      ]
    },
    {
      "type": "info_list",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.yazio_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.yazio_affirmation.title"
      },
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/calorie_counting_app"
      },
      "infoList": [
        {
          "titleTranslationKey": "onboarding.encouraging_flow.calorie_counting.yazio_affirmation.tracking",
          "emoji": "🕑",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.calorie_counting.yazio_affirmation.customize",
          "emoji": "🖌️",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.calorie_counting.yazio_affirmation.customer_care",
          "emoji": "👨‍💻",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.calorie_counting.yazio_affirmation.community",
          "emoji": "👥",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.count_me_in",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.difficult"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.difficult",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.difficult.question"
      },
      "options": [
        {
          "emoji": "😢",
          "translationKey": "system.general.button.yes",
          "trackingName": "system.general.button.yes",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.difficult_yes_affirmation"
          }
        },
        {
          "emoji": "😎",
          "translationKey": "system.general.button.no",
          "trackingName": "system.general.button.no",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.difficult_no_affirmation"
          }
        }
      ],
      "optionsLayout": "Grid"
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.difficult_yes_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.difficult_yes_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [
          {
            "condition": "GoalGainWeight",
            "value": "onboarding.encouraging_flow.gain_weight.calorie_counting.difficult_yes_affirmation.description"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "onboarding.encouraging_flow.build_muscle.calorie_counting.difficult_yes_affirmation.description"
          }
        ],
        "fallback": "onboarding.encouraging_flow.calorie_counting.difficult_yes_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/making_plans"
      },
      "nextButtonTranslationKey": "system.general.button.sounds_amazing",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.fasting.experience"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.difficult_no_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.difficult_no_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.difficult_no_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/making_plans"
      },
      "nextButtonTranslationKey": "system.general.button.absolutely",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.fasting.experience"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.info",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.info.question"
      },
      "options": [
        {
          "emoji": "👍",
          "translationKey": "system.general.button.yes",
          "trackingName": "system.general.button.yes",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.calorie_counting.info_affirmation"
          }
        },
        {
          "emoji": "👎",
          "translationKey": "system.general.button.no",
          "trackingName": "system.general.button.no",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.fasting.experience"
          }
        }
      ],
      "optionsLayout": "Grid"
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.calorie_counting.info_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.calorie_counting.info_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [
          {
            "condition": "GoalGainWeight",
            "value": "onboarding.encouraging_flow.build_muscle.calorie_counting.info_affirmation.description"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "onboarding.encouraging_flow.build_muscle.calorie_counting.info_affirmation.description"
          }
        ],
        "fallback": "onboarding.encouraging_flow.calorie_counting.info_affirmation.description"
      },
      "imageSize": "MEDIUM",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.calorie_counting.info_affirmation"
      },
      "nextButtonTranslationKey": "system.general.button.got_it",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.fasting.experience"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.fasting.experience",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.fasting.experience.question"
      },
      "options": [
        {
          "emoji": "🤩",
          "translationKey": "onboarding.encouraging_flow.fasting.experience.yes_positive",
          "trackingName": "onboarding.encouraging_flow.fasting.experience.yes_positive",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.fasting.positive_affirmation"
          }
        },
        {
          "emoji": "🤔",
          "translationKey": "onboarding.encouraging_flow.fasting.experience.yes_negative",
          "trackingName": "onboarding.encouraging_flow.fasting.experience.yes_negative",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.mood.plan"
          }
        },
        {
          "emoji": "🙋",
          "translationKey": "onboarding.encouraging_flow.fasting.experience.no_positive",
          "trackingName": "onboarding.encouraging_flow.fasting.experience.no_positive",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.fasting.positive_affirmation"
          }
        },
        {
          "emoji": "🙅",
          "translationKey": "onboarding.encouraging_flow.fasting.experience.no_negative",
          "trackingName": "onboarding.encouraging_flow.fasting.experience.no_negative",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.mood.plan"
          }
        },
        {
          "emoji": "❓",
          "translationKey": "onboarding.encouraging_flow.fasting.experience.other",
          "trackingName": "onboarding.encouraging_flow.fasting.experience.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.fasting.explanation_affirmation"
          }
        }
      ]
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.fasting.explanation_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.fasting.explanation_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [
          {
            "condition": "GoalGainWeight",
            "value": "onboarding.encouraging_flow.build_muscle.fasting.explanation_affirmation.description"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "onboarding.encouraging_flow.build_muscle.fasting.explanation_affirmation.description"
          }
        ],
        "fallback": "onboarding.encouraging_flow.fasting.explanation_affirmation.description"
      },
      "imageSize": "MEDIUM",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.fasting.explanation_affirmation"
      },
      "nextButtonTranslationKey": "system.general.button.got_it",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.fasting.try"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.fasting.try",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.fasting.try.question"
      },
      "options": [
        {
          "emoji": "👍",
          "translationKey": "system.general.button.yes",
          "trackingName": "system.general.button.yes",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.fasting.positive_affirmation"
          }
        },
        {
          "emoji": "👎",
          "translationKey": "system.general.button.no",
          "trackingName": "system.general.button.no",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.mood.plan"
          }
        }
      ],
      "optionsLayout": "Grid"
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.fasting.positive_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.fasting.positive_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.fasting.positive_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.fasting.positive_affirmation"
      },
      "nextButtonTranslationKey": "system.general.button.love_it",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.mood.plan"
      }
    },
    {
      "type": "multi_choice",
      "id": "screenid:onboarding.encouraging_flow.mood.plan",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.mood.plan.question"
      },
      "options": [
        {
          "emoji": "🍎",
          "translationKey": "onboarding.encouraging_flow.mood.plan.advanced_logging",
          "trackingName": "onboarding.encouraging_flow.mood.plan.advanced_logging",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🧑‍🤝‍🧑",
          "translationKey": "onboarding.encouraging_flow.mood.plan.accountability",
          "trackingName": "onboarding.encouraging_flow.mood.plan.accountability",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🍱",
          "translationKey": "onboarding.encouraging_flow.mood.plan.meal_prep",
          "trackingName": "onboarding.encouraging_flow.mood.plan.meal_prep",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🔥",
          "translationKey": "onboarding.encouraging_flow.mood.plan.streak",
          "trackingName": "onboarding.encouraging_flow.mood.plan.streak",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "👀",
          "translationKey": "onboarding.encouraging_flow.mood.plan.calorie_balance",
          "trackingName": "onboarding.encouraging_flow.mood.plan.calorie_balance",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🤷",
          "translationKey": "onboarding.encouraging_flow.mood.plan.other",
          "trackingName": "onboarding.encouraging_flow.mood.plan.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "requireAdditionalAnswer": true
        }
      ],
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.mood.journey"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.mood.journey",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.mood.journey.question"
      },
      "options": [
        {
          "emoji": "🤩",
          "translationKey": "onboarding.encouraging_flow.mood.journey.motivated",
          "trackingName": "onboarding.encouraging_flow.mood.journey.motivated",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.mood.positive_affirmation"
          }
        },
        {
          "emoji": "😎",
          "translationKey": "onboarding.encouraging_flow.mood.journey.confident",
          "trackingName": "onboarding.encouraging_flow.mood.journey.confident",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.mood.positive_affirmation"
          }
        },
        {
          "emoji": "😰",
          "translationKey": "onboarding.encouraging_flow.mood.journey.nervous",
          "trackingName": "onboarding.encouraging_flow.mood.journey.nervous",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.mood.negative_affirmation"
          }
        },
        {
          "emoji": "🤨",
          "translationKey": "onboarding.encouraging_flow.mood.journey.frustrated",
          "trackingName": "onboarding.encouraging_flow.mood.journey.frustrated",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.mood.negative_affirmation"
          }
        },
        {
          "emoji": "😴",
          "translationKey": "onboarding.encouraging_flow.mood.journey.unmotivated",
          "trackingName": "onboarding.encouraging_flow.mood.journey.unmotivated",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.mood.negative_affirmation"
          }
        },
        {
          "emoji": "😶",
          "translationKey": "onboarding.encouraging_flow.mood.journey.other",
          "trackingName": "onboarding.encouraging_flow.mood.journey.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.mood.unsure_affirmation"
          }
        }
      ]
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.mood.positive_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.mood.positive_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.mood.positive_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/achievements"
      },
      "nextButtonTranslationKey": "system.general.button.im_ready",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.motivation.excited"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.mood.unsure_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.mood.unsure_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.mood.unsure_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.mood.unsure_affirmation"
      },
      "nextButtonTranslationKey": "system.general.button.lets_do_this",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.motivation.excited"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.mood.negative_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.mood.negative_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.mood.negative_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.mood.negative_affirmation"
      },
      "nextButtonTranslationKey": "system.general.button.lets_do_this",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.motivation.excited"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.motivation.excited",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.motivation.excited.question"
      },
      "options": [
        {
          "emoji": "🚀",
          "translationKey": "onboarding.encouraging_flow.motivation.excited.healthy_living",
          "trackingName": "onboarding.encouraging_flow.motivation.excited.healthy_living",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation"
          }
        },
        {
          "emoji": "🌟",
          "translationKey": "onboarding.encouraging_flow.motivation.excited.confidence",
          "trackingName": "onboarding.encouraging_flow.motivation.excited.confidence",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation"
          }
        },
        {
          "emoji": "👖",
          "translationKey": "onboarding.encouraging_flow.motivation.excited.self_clothing",
          "trackingName": "onboarding.encouraging_flow.motivation.excited.self_clothing",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation"
          }
        },
        {
          "emoji": "📏",
          "translationKey": "onboarding.encouraging_flow.motivation.excited.measurements",
          "trackingName": "onboarding.encouraging_flow.motivation.excited.measurements",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation"
          }
        },
        {
          "emoji": "💪",
          "translationKey": "onboarding.encouraging_flow.motivation.excited.fitness",
          "trackingName": "onboarding.encouraging_flow.motivation.excited.fitness",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation"
          }
        },
        {
          "emoji": "💪",
          "translationKey": "onboarding.encouraging_flow.gain_weight.motivation.excited.fitness",
          "trackingName": "onboarding.encouraging_flow.gain_weight.motivation.excited.fitness",
          "visible": {
            "conditions": [
              {
                "condition": "GoalGainWeight",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation"
          }
        },
        {
          "emoji": "💪",
          "translationKey": "onboarding.encouraging_flow.build_muscle.motivation.excited.fitness",
          "trackingName": "onboarding.encouraging_flow.build_muscle.motivation.excited.fitness",
          "visible": {
            "conditions": [
              {
                "condition": "GoalBuildMuscle",
                "value": true
              }
            ],
            "fallback": false
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation"
          }
        },
        {
          "emoji": "💬",
          "translationKey": "onboarding.encouraging_flow.motivation.excited.other",
          "trackingName": "onboarding.encouraging_flow.motivation.excited.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation"
          }
        }
      ]
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.motivation.think_back_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.motivation.think_back_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.motivation.think_back_affirmation.description"
      },
      "imageSize": "MEDIUM",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.motivation.think_back_affirmation"
      },
      "nextButtonTranslationKey": "system.general.button.start_my_journey",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.height"
      }
    },
    {
      "type": "info_list",
      "id": "screenid:onboarding.encouraging_flow.short.start_journey_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.short.start_journey_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.short.start_journey_affirmation.description"
      },
      "imageSize": "MEDIUM",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.short.start_journey_affirmation"
      },
      "infoList": [
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.start_journey_affirmation.calories",
          "emoji": "🔢",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.start_journey_affirmation.macros",
          "emoji": "🥗",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.start_journey_affirmation.fasting",
          "emoji": "⏳",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.start_journey_affirmation.habits",
          "emoji": "🍏",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.short.welcome_new_you_affirmation"
      }
    },
    {
      "type": "info_list",
      "id": "screenid:onboarding.encouraging_flow.short.welcome_new_you_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.short.welcome_new_you_affirmation.title"
      },
      "imageSize": "MEDIUM",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.short.welcome_new_you_affirmation"
      },
      "infoList": [
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.welcome_new_you_affirmation.goals",
          "emoji": "🔬",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.welcome_new_you_affirmation.results",
          "emoji": "🔗",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.welcome_new_you_affirmation.tools",
          "emoji": "👩‍🔬",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "registration.gender.info.button",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.short.goal"
      }
    },
    {
      "type": "multi_choice",
      "id": "screenid:onboarding.encouraging_flow.short.goal",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.short.goal.question"
      },
      "options": [
        {
          "emoji": "🍏",
          "translationKey": "onboarding.encouraging_flow.short.goal.healthy_living",
          "trackingName": "onboarding.encouraging_flow.short.goal.healthy_living",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "☀️",
          "translationKey": "onboarding.encouraging_flow.short.goal.energy",
          "trackingName": "onboarding.encouraging_flow.short.goal.energy",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "💪",
          "translationKey": "onboarding.encouraging_flow.short.goal.motivation",
          "trackingName": "onboarding.encouraging_flow.short.goal.motivation",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🧘",
          "translationKey": "onboarding.encouraging_flow.short.goal.body_image",
          "trackingName": "onboarding.encouraging_flow.short.goal.body_image",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.short.yazio_help"
      }
    },
    {
      "type": "multi_choice",
      "id": "screenid:onboarding.encouraging_flow.short.yazio_help",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.short.yazio_help.question"
      },
      "options": [
        {
          "emoji": "🔢",
          "translationKey": "onboarding.encouraging_flow.short.yazio_help.calorie_counting",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.yazio_help.calorie_counting_caption",
          "trackingName": "onboarding.encouraging_flow.short.yazio_help.calorie_counting",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🏃",
          "translationKey": "onboarding.encouraging_flow.short.yazio_help.activities",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.yazio_help.activities_caption",
          "trackingName": "onboarding.encouraging_flow.short.yazio_help.activities",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🥗",
          "translationKey": "onboarding.encouraging_flow.short.yazio_help.healthy_eating",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.yazio_help.healthy_eating_caption",
          "trackingName": "onboarding.encouraging_flow.short.yazio_help.healthy_eating",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "📊",
          "translationKey": "onboarding.encouraging_flow.short.yazio_help.analyses",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.yazio_help.analyses_caption",
          "trackingName": "onboarding.encouraging_flow.short.yazio_help.analyses",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "⏳",
          "translationKey": "onboarding.encouraging_flow.short.yazio_help.fasting",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.yazio_help.fasting_caption",
          "trackingName": "onboarding.encouraging_flow.short.yazio_help.fasting",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.short.nutrition"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.short.nutrition",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.short.nutrition.question"
      },
      "options": [
        {
          "emoji": "😐",
          "translationKey": "onboarding.encouraging_flow.short.nutrition.beginner",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.nutrition.beginner_caption",
          "trackingName": "onboarding.encouraging_flow.short.nutrition.beginner",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.short.bad_habits"
          }
        },
        {
          "emoji": "🙂",
          "translationKey": "onboarding.encouraging_flow.short.nutrition.intermediate",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.nutrition.intermediate_caption",
          "trackingName": "onboarding.encouraging_flow.short.nutrition.intermediate",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.short.bad_habits"
          }
        },
        {
          "emoji": "😎",
          "translationKey": "onboarding.encouraging_flow.short.nutrition.advanced",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.nutrition.advanced_caption",
          "trackingName": "onboarding.encouraging_flow.short.nutrition.advanced",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.short.bad_habits"
          }
        }
      ]
    },
    {
      "type": "multi_choice",
      "id": "screenid:onboarding.encouraging_flow.short.bad_habits",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.short.bad_habits.question"
      },
      "options": [
        {
          "emoji": "🥣",
          "translationKey": "onboarding.encouraging_flow.short.bad_habits.portion_control",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.bad_habits.portion_control_caption",
          "trackingName": "onboarding.encouraging_flow.short.bad_habits.portion_control",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🍫",
          "translationKey": "onboarding.encouraging_flow.short.bad_habits.snacking",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.bad_habits.snacking_caption",
          "trackingName": "onboarding.encouraging_flow.short.bad_habits.snacking",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🍲",
          "translationKey": "onboarding.encouraging_flow.short.bad_habits.balance",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.bad_habits.balance_caption",
          "trackingName": "onboarding.encouraging_flow.short.bad_habits.balance",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "💧",
          "translationKey": "onboarding.encouraging_flow.short.bad_habits.hydration",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.bad_habits.hydration_caption",
          "trackingName": "onboarding.encouraging_flow.short.bad_habits.hydration",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🏃",
          "translationKey": "onboarding.encouraging_flow.short.bad_habits.exercise",
          "descriptionTranslationKey": "onboarding.encouraging_flow.short.bad_habits.exercise_caption",
          "trackingName": "onboarding.encouraging_flow.short.bad_habits.exercise",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.short.barriers"
      }
    },
    {
      "type": "multi_choice",
      "id": "screenid:onboarding.encouraging_flow.short.barriers",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.short.barriers.question"
      },
      "options": [
        {
          "emoji": "🎢",
          "translationKey": "onboarding.encouraging_flow.short.barriers.consistency",
          "trackingName": "onboarding.encouraging_flow.short.barriers.consistency",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🍟",
          "translationKey": "onboarding.encouraging_flow.short.barriers.eating_habits",
          "trackingName": "onboarding.encouraging_flow.short.barriers.eating_habits",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🤝",
          "translationKey": "onboarding.encouraging_flow.short.barriers.support",
          "trackingName": "onboarding.encouraging_flow.short.barriers.support",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "📅",
          "translationKey": "onboarding.encouraging_flow.short.barriers.busy",
          "trackingName": "onboarding.encouraging_flow.short.barriers.busy",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "emoji": "🥘",
          "translationKey": "onboarding.encouraging_flow.short.barriers.meal_inspiration",
          "trackingName": "onboarding.encouraging_flow.short.barriers.meal_inspiration",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.short.weight_management_affirmation"
      }
    },
    {
      "type": "info_list",
      "id": "screenid:onboarding.encouraging_flow.short.weight_management_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.short.weight_management_affirmation.title"
      },
      "imageSize": "MEDIUM",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.short.weight_management_affirmation"
      },
      "infoList": [
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.weight_management_affirmation.consistency",
          "emoji": "🏆",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.weight_management_affirmation.holistic_tracking",
          "emoji": "🏠",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.short.weight_management_affirmation.accuracy",
          "emoji": "🎯",
          "visible": {
            "conditions": [],
            "fallback": true
          }
        }
      ],
      "nextButtonTranslationKey": "system.general.button.got_it",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.height"
      }
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.activity_level"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.height",
      "staticScreenType": "height"
    },
    {
      "type": "activity_level",
      "id": "screenid:onboarding.encouraging_flow.personal.activity_level",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "registration.activity.headline"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "registration.activity.teaser"
      },
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.current_weight"
      }
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [
          {
            "condition": "GoalLooseWeight",
            "value": "screenid:onboarding.encouraging_flow.personal_goal.look_ahead_affirmation"
          }
        ],
        "fallback": "screenid:onboarding.encouraging_flow.personal_goal.goal_weight"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.current_weight",
      "staticScreenType": "current_weight"
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.personal_goal.look_ahead_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.personal_goal.look_ahead_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.personal_goal.look_ahead_affirmation.description"
      },
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.personal_goal.look_ahead_affirmation"
      },
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal_goal.goal_weight"
      }
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal.birthday"
      },
      "id": "screenid:onboarding.encouraging_flow.personal_goal.goal_weight",
      "staticScreenType": "target_weight"
    },
    {
      "type": "static",
      "nextStep": {
        "conditions": [
          {
            "condition": "GoalMaintainWeight",
            "value": "screenid:onboarding.encouraging_flow.diet.dietary_preferences"
          },
          {
            "condition": "GoalElse",
            "value": "screenid:onboarding.encouraging_flow.diet.dietary_preferences"
          }
        ],
        "fallback": "screenid:onboarding.encouraging_flow.personal_goal.positive_effects_affirmation"
      },
      "id": "screenid:onboarding.encouraging_flow.personal.birthday",
      "staticScreenType": "birthday"
    },
    {
      "type": "info_list",
      "id": "screenid:onboarding.encouraging_flow.personal_goal.positive_effects_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.personal_goal.positive_effects_affirmation.title"
      },
      "imageSize": "MEDIUM",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.personal_goal.positive_effects_affirmation"
      },
      "infoList": [
        {
          "titleTranslationKey": "onboarding.encouraging_flow.personal_goal.positive_effects_affirmation.benefit_diabetes",
          "emoji": "🍭",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.personal_goal.positive_effects_affirmation.benefit_blood_pressure",
          "emoji": "❤️",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.personal_goal.positive_effects_affirmation.benefit_cholesterol",
          "emoji": "🌱",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.build_muscle.personal_goal.positive_effects_affirmation.benefit_metabolism",
          "emoji": "🔄",
          "visible": {
            "conditions": [
              {
                "condition": "GoalBuildMuscle",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.gain_weight.personal_goal.positive_effects_affirmation.benefit_immune",
          "emoji": "🛡️",
          "visible": {
            "conditions": [
              {
                "condition": "GoalLooseWeight",
                "value": false
              }
            ],
            "fallback": true
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.gain_weight.personal_goal.positive_effects_affirmation.benefit_energy",
          "emoji": "❤️",
          "visible": {
            "conditions": [
              {
                "condition": "GoalGainWeight",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.gain_weight.personal_goal.positive_effects_affirmation.benefit_stress",
          "emoji": "🧘",
          "visible": {
            "conditions": [
              {
                "condition": "GoalGainWeight",
                "value": true
              }
            ],
            "fallback": false
          }
        },
        {
          "titleTranslationKey": "onboarding.encouraging_flow.build_muscle.personal_goal.positive_effects_affirmation.benefit_energy",
          "emoji": "🧘",
          "visible": {
            "conditions": [
              {
                "condition": "GoalBuildMuscle",
                "value": true
              }
            ],
            "fallback": false
          }
        }
      ],
      "nextButtonTranslationKey": "registration.gender.info.button",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal_goal.special_event"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.personal_goal.special_event",
      "titleTranslationKey": {
        "conditions": [
          {
            "condition": "GoalGainWeight",
            "value": "onboarding.encouraging_flow.gain_weight.personal_goal.special_event.question"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "onboarding.encouraging_flow.build_muscle.personal_goal.special_event.question"
          }
        ],
        "fallback": "onboarding.encouraging_flow.personal_goal.special_event.question"
      },
      "options": [
        {
          "emoji": "🏖️",
          "translationKey": "onboarding.encouraging_flow.personal_goal.special_event.vacation",
          "trackingName": "onboarding.encouraging_flow.personal_goal.special_event.vacation",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.personal_goal.event_date"
          }
        },
        {
          "emoji": "💍",
          "translationKey": "onboarding.encouraging_flow.personal_goal.special_event.wedding",
          "trackingName": "onboarding.encouraging_flow.personal_goal.special_event.wedding",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.personal_goal.event_date"
          }
        },
        {
          "emoji": "🏆",
          "translationKey": "onboarding.encouraging_flow.personal_goal.special_event.sports",
          "trackingName": "onboarding.encouraging_flow.personal_goal.special_event.sports",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.personal_goal.event_date"
          }
        },
        {
          "emoji": "☀️",
          "translationKey": "onboarding.encouraging_flow.personal_goal.special_event.summer",
          "trackingName": "onboarding.encouraging_flow.personal_goal.special_event.summer",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.personal_goal.event_date"
          }
        },
        {
          "emoji": "👨‍👩‍👧‍👦",
          "translationKey": "onboarding.encouraging_flow.personal_goal.special_event.reunion",
          "trackingName": "onboarding.encouraging_flow.personal_goal.special_event.reunion",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.personal_goal.event_date"
          }
        },
        {
          "emoji": "💬",
          "translationKey": "onboarding.encouraging_flow.personal_goal.special_event.other",
          "trackingName": "onboarding.encouraging_flow.personal_goal.special_event.other",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.personal_goal.event_date"
          }
        },
        {
          "emoji": "🗓️",
          "translationKey": "onboarding.encouraging_flow.personal_goal.special_event.none",
          "trackingName": "onboarding.encouraging_flow.personal_goal.special_event.none",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.personal_goal.reviews_affirmation"
          }
        }
      ]
    },
    {
      "type": "date",
      "id": "screenid:onboarding.encouraging_flow.personal_goal.event_date",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.personal_goal.event_date.title"
      },
      "nextButtonTranslationKey": "system.general.button.next",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal_goal.set_goal_affirmation"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.personal_goal.set_goal_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.personal_goal.set_goal_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.personal_goal.set_goal_affirmation.description"
      },
      "imageSize": "MEDIUM",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/look_forward_2"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.personal_goal.reviews_affirmation"
      }
    },
    {
      "type": "support_with_reviews",
      "id": "screenid:onboarding.encouraging_flow.personal_goal.reviews_affirmation",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.time.minutes_per_day"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.time.minutes_per_day",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.time.minutes_per_day.question"
      },
      "options": [
        {
          "emoji": "🦦",
          "translationKey": "onboarding.encouraging_flow.time.minutes_per_day.casual",
          "trackingName": "onboarding.encouraging_flow.time.minutes_per_day.casual",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.time.low_time_usage_per_day_affirmation"
          }
        },
        {
          "emoji": "🐇",
          "translationKey": "onboarding.encouraging_flow.time.minutes_per_day.regular",
          "trackingName": "onboarding.encouraging_flow.time.minutes_per_day.regular",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.time.low_time_usage_per_day_affirmation"
          }
        },
        {
          "emoji": "🦘",
          "translationKey": "onboarding.encouraging_flow.time.minutes_per_day.serious",
          "trackingName": "onboarding.encouraging_flow.time.minutes_per_day.serious",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.time.high_time_usage_per_day_affirmation"
          }
        },
        {
          "emoji": "🐆",
          "translationKey": "onboarding.encouraging_flow.time.minutes_per_day.intense",
          "trackingName": "onboarding.encouraging_flow.time.minutes_per_day.intense",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.time.high_time_usage_per_day_affirmation"
          }
        }
      ]
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.time.low_time_usage_per_day_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.time.low_time_usage_per_day_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.time.low_time_usage_per_day_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/lotus_position"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.time.days_in_a_row"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.time.high_time_usage_per_day_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.time.high_time_usage_per_day_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.time.high_time_usage_per_day_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/lotus_position"
      },
      "nextButtonTranslationKey": "system.general.button.lets_go",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.time.days_in_a_row"
      }
    },
    {
      "type": "single_choice",
      "id": "screenid:onboarding.encouraging_flow.time.days_in_a_row",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.time.days_in_a_row.question"
      },
      "options": [
        {
          "emoji": "🚀",
          "translationKey": "onboarding.encouraging_flow.time.days_in_a_row.unstoppable",
          "trackingName": "onboarding.encouraging_flow.time.days_in_a_row.unstoppable",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.time.streaks_affirmation"
          }
        },
        {
          "emoji": "🚅",
          "translationKey": "onboarding.encouraging_flow.time.days_in_a_row.incredible",
          "trackingName": "onboarding.encouraging_flow.time.days_in_a_row.incredible",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.time.streaks_affirmation"
          }
        },
        {
          "emoji": "🚲",
          "translationKey": "onboarding.encouraging_flow.time.days_in_a_row.great",
          "trackingName": "onboarding.encouraging_flow.time.days_in_a_row.great",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.time.streaks_affirmation"
          }
        },
        {
          "emoji": "👟",
          "translationKey": "onboarding.encouraging_flow.time.days_in_a_row.good",
          "trackingName": "onboarding.encouraging_flow.time.days_in_a_row.good",
          "visible": {
            "conditions": [],
            "fallback": true
          },
          "nextStep": {
            "conditions": [],
            "fallback": "screenid:onboarding.encouraging_flow.time.streaks_affirmation"
          }
        }
      ]
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.time.streaks_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.time.streaks_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.time.streaks_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/time_streaks"
      },
      "nextButtonTranslationKey": "system.general.button.ive_got_this",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.diet.dietary_preferences"
      }
    },
    {
      "type": "diet",
      "id": "screenid:onboarding.encouraging_flow.diet.dietary_preferences",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "registration.dietary_preferences.headline"
      },
      "captionTranslationKey": {
        "conditions": [],
        "fallback": "registration.dietary_preferences.teaser"
      },
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.diet.recipes_affirmation"
      }
    },
    {
      "type": "illustrations_recipes",
      "id": "screenid:onboarding.encouraging_flow.diet.recipes_affirmation",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.trust_affirmation"
      }
    },
    {
      "type": "affirmation",
      "id": "screenid:onboarding.encouraging_flow.plan.trust_affirmation",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "onboarding.encouraging_flow.plan.trust_affirmation.title"
      },
      "captionTranslationKey": {
        "conditions": [
          {
            "condition": "GoalLooseWeight",
            "value": "onboarding.encouraging_flow.plan.trust_affirmation.description_alternative"
          },
          {
            "condition": "GoalBuildMuscle",
            "value": "onboarding.encouraging_flow.build_muscle.plan.trust_affirmation.description"
          },
          {
            "condition": "GoalGainWeight",
            "value": "onboarding.encouraging_flow.gain_weight.plan.trust_affirmation.description"
          }
        ],
        "fallback": "onboarding.encouraging_flow.plan.trust_affirmation.description"
      },
      "imageSize": "LARGE",
      "imageUrl": {
        "conditions": [],
        "fallback": "process/plain/app/misc/illustrations/onboarding/onboarding.encouraging_flow.plan.trust_affirmation"
      },
      "nextButtonTranslationKey": "system.general.button.create_plan",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.load_plan_1"
      }
    },
    {
      "type": "prepare_plan",
      "id": "screenid:onboarding.encouraging_flow.plan.load_plan_1",
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:onboarding.encouraging_flow.plan.benefits"
      },
      "titleTranslationKey": {
        "conditions": [],
        "fallback": ""
      },
      "steps": []
    },
    {
      "type": "static",
      "titleTranslationKey": {
        "conditions": [],
        "fallback": "app.profunnel.benefit_screen.heading.what_you_can_look_forward_to"
      },
      "nextStep": {
        "conditions": [],
        "fallback": "screenid:end"
      },
      "staticScreenType": "benefits",
      "id": "screenid:onboarding.encouraging_flow.plan.benefits"
    }
  ]
};
