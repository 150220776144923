<script lang="ts" setup>
import { computed } from "vue";
import OnboardingBtn from "~/components/onboarding/OnboardingBtn.vue";
import type { SingleChoiceScreen } from "~/types/onboarding/onboardingScreen";
import type { RegistrationParams } from "~/types/onboarding/registrationParams";
import { useOnboardingStore } from "~/stores/useOnboardingStore";
import AppAlertDanger from "~/components/app/AppAlertDanger.vue";
import { getOnboardingConditions } from "~/utils/onboardingConditions";

interface Props {
  screen: SingleChoiceScreen;
  registrationParams: RegistrationParams;
  title: string;
}

const props = defineProps<Props>();
const { getOrGetConditionalValue } = getOnboardingConditions(props);

interface Emits {
  (
    e: "next",
    value: {
      params: Partial<RegistrationParams>;
      nextScreenId: string;
    },
  ): void;
}

const emit = defineEmits<Emits>();
const { addActionEvent } = useTrackingStore();
const { t } = useNuxtApp().$i18n;
const useGridLayout = computed(() => props.screen.optionsLayout === "Grid");
const { $pinia } = useNuxtApp();
const { singleChoiceSelectedOptions } = storeToRefs(useOnboardingStore($pinia));

function selectOption(option: SingleChoiceScreen["options"][number]) {
  if (props.screen?.id === "screenid:onboarding.encouraging_flow.personal.personalized_question") {
    singleChoiceSelectedOptions.value[props.screen.id] = option.translationKey;
  }

  emit("next", {
    params: {},
    nextScreenId: getOrGetConditionalValue(option.nextStep),
  });

  addActionEvent(stripScreenIdPrefix(props.screen.id) + ".answer", {
    selection: [option.trackingName],
    screenType: props.screen.type,
  });
}

const caption = computed(() => {
  if (!props.screen?.captionTranslationKey) {
    return "";
  }

  return t(getOrGetConditionalValue(props.screen?.captionTranslationKey));
});
</script>

<template>
  <div
    v-if="screen"
    :class="{
      'flex flex-col gap-16': useGridLayout,
    }"
  >
    <h1 v-if="title">
      {{ title }}
    </h1>
    <span v-if="caption" class="mb-4 block">{{ caption }}</span>
    <div
      class="grid gap-4"
      :class="{
        'grid-cols-2': useGridLayout,
      }"
    >
      <OnboardingBtn
        v-for="(option, key) in screen.options"
        :key="key"
        :emoji="option.emoji"
        :translation-key="option.translationKey"
        :description-translation-key="option.descriptionTranslationKey"
        :is-column="useGridLayout"
        @click="selectOption(option)"
      />
    </div>
  </div>
  <AppAlertDanger v-else class="my-3"> {{ $t(`system.general.message.unknown_error`) }} </AppAlertDanger>
</template>
